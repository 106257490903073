import { useVitalLink } from '@tryvital/vital-link';
import { useState, useCallback, useEffect } from 'react';
import axios from 'axios'

function App() {
  const [flag, setFlag] = useState(false)

  const generateLinkToken = async (id) => {
    const response = await axios.patch(
      'https://devcatalog.gorrywell.com/api/vital/integration/' + id
    )
    
    let output = null

    if(response.status === 200) {
      if(response.data.data.link_token) {
        output = response.data.data.link_token
      }
    }

    return output
  }

  const onSuccess = useCallback(metadata => {
    // Device is now connected.
    console.log('success', metadata)
    if(metadata.state === 'success') {
      const queryParameters = new URLSearchParams(window.location.search)
      const id = queryParameters.get("key")

      axios.put(
        'https://devcatalog.gorrywell.com/api/vital/success',
        {
          provider: metadata.source_slug,
          userId: id
        }
      ).then(res => {
        console.log('success sync', res)
      }).catch(error => {
        console.error(error)
      })

      window.location.assign('https://devcatalog.gorrywell.com/api/google-apis/redirect/success')
    }
  }, []);

  const onExit = useCallback(metadata => {
    // User has quit the link flow.
    console.log('unfinished', metadata)
  }, []);

  const onError = useCallback(metadata => {
    // Error encountered in connecting device.
    console.log('error', metadata)
    window.location.assign('https://devcatalog.gorrywell.com/api/google-apis/redirect/failed')
  }, []);

  const config = {
    onSuccess,
    onExit,
    onError,
    env: "sandbox"
  };

  const { open, ready, error } = useVitalLink(config);

  const handleVitalOpen = async (id) => {
    const token = await generateLinkToken(id)
    open(token);
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const id = queryParameters.get("key")

    if(id && id.length > 0) {
      if(ready) {
        handleVitalOpen(id)
      }
    } else {
      setFlag(true)
    }
  }, [ready])

  return (
    <div className="App">
      {
      flag === false
      ?
      (
        <div id="loader"></div>        
      )
      : 
      (
        <p>Please attach the query key</p>
      )
      }
    </div>
  );
}

export default App;
